import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { Input, Button } from "expano-components";
import { AsyncSelect } from "components";
import { Main } from "layouts";
import { connect } from "react-redux";
import getWarehouses from "utils/queries/getWarehouses";
import { createInventorization } from "actions/inventorizations";

import { ReactComponent as ProductIcon } from "icons/product.svg";
import { ReactComponent as InfoIcon } from "icons/info.svg";

const InputField = (props) => <Input {...props} />;

const CreateInventorization = ({
  change,
  pristine,
  submitting,
  handleSubmit,
  history,
}) => {
  const [is_saving, setSaving] = useState(false);

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      const id = await createInventorization(values);
      history.push(`/inventorizations/${id}`);
    } catch (error) {
      throw error;
    } finally {
      setSaving(false);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Main
        page={{
          name: "Utwórz inwentaryzację",
          icon: <ProductIcon />,
          breadcrumbs: [
            { name: "Inwentaryzacje", path: "/inventorizations" },
            { name: "Utwórz inwentaryzację" },
          ],
          buttons: (
            <Button
              disabled={is_saving || pristine || submitting}
              type="save"
              text={is_saving ? "Proszę czekać..." : "Utwórz"}
              onClick={handleSubmit(onSubmit)}
            />
          ),
        }}
      >
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <div className="dashboard">
              <header className="dashboard__header">
                <div className="icon__container">
                  <InfoIcon />
                </div>
                <h2 className="dashboard__title heading">
                  Podstawowe informacje
                </h2>
              </header>
              <div className="dashboard__form p-t-20">
                <div className="dashboard-wrapper-flex m-b-20">
                  <div className="column-half">
                    <Field name="name" label="Nazwa" component={InputField} />
                  </div>
                  <div className="column-half">
                    <AsyncSelect
                      label="Magazyn"
                      getData={(q) => getWarehouses(q)}
                      onChange={({ value }) => change("warehouse_id", value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  ["name", "warehouse_id"].forEach((name) => {
    if (!values[name]) {
      errors[name] = "Pole wymagane";
    }
  });

  return errors;
};

export default connect(() => ({
  initialValues: {
    name: "",
    warehouse_id: null,
  },
}))(
  reduxForm({
    form: "create-inventorization",
    enableReinitialize: true,
    validate,
  })(CreateInventorization)
);
