const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: {},
  },
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "INVENTORIZATIONS_TOGGLE_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          open: !state.filters.open,
        },
      };
    case "INVENTORIZATIONS_RESET_FILTERS":
      return {
        ...state,
        filters: {
          open: true,
          query: {},
        },
      };
    case "INVENTORIZATIONS_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "INVENTORIZATIONS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "INVENTORIZATIONS_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "INVENTORIZATIONS_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.data,
        meta: action.meta,
      };
    case "INVENTORIZATIONS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
