import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MainTable } from "components";
import { Main } from "layouts";
import { parseQuery } from "utils/api";
import {
  getInventorizations,
  filterInventorizations,
  toggleFilters,
  resetFilters,
} from "actions/inventorizations";
import { getInventorizationStatusLabel } from "utils/inventorization_statuses";
import getWarehouses from "utils/queries/getWarehouses";

import { Button } from "expano-components";
import { ReactComponent as ProductIcon } from "icons/product.svg";

const InventorizationsList = ({
  inventorizations,
  getInventorizations,
  filterInventorizations,
  toggleFilters,
  resetFilters,
  location: { search },
  history,
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const query = parseQuery(search);
  const sort = {
    "sort[column]": query["sort[column]"] || "created_at",
    "sort[order]": query["sort[order]"] || "desc",
  };

  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, []);

  useEffect(() => {
    getInventorizations({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getInventorizations({ ...query, page: undefined });
    }
  }, [inventorizations.filters.query]);

  if (inventorizations.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = inventorizations;

  return (
    <Main
      page={{
        name: "Inwentaryzacje",
        icon: <ProductIcon />,
        breadcrumbs: [{ name: "Inwentaryzacje" }],
        buttons: (
          <Button
            type="add"
            text="Dodaj"
            onClick={() => history.push("/inventorizations/new")}
          />
        ),
      }}
    >
      <div className="dashboard">
        <MainTable
          is_loading={["invalid", "loading"].includes(inventorizations.status)}
          is_searching={inventorizations.status === "searching"}
          filters_open={inventorizations.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            {
              label: "Nazwa",
              name: "name",
            },
            {
              label: "Magazyn",
              name: "warehouse_id",
            },
            {
              label: "Status",
              name: "status",
            },
          ]}
          empty_text="Brak danych"
          renderRow={({ id, name, warehouse, status }) => (
            <tr
              key={id}
              style={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`/inventorizations/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`/inventorizations/${id}`)}
            >
              <td>{name}</td>
              <td>{warehouse?.name}</td>
              <td>{getInventorizationStatusLabel(status)}</td>
            </tr>
          )}
          filtersAction={filterInventorizations}
          filters={[
            {
              type: "async-multi-select",
              label: "Magazyn",
              search_type: "in",
              asyncAction: getWarehouses,
              name: "warehouse_id",
            },
          ]}
        />
      </div>
    </Main>
  );
};

export default connect(
  ({ inventorizations }) => ({ inventorizations }),
  (dispatch) => ({
    getInventorizations: (data) => dispatch(getInventorizations(data)),
    filterInventorizations: (data) => dispatch(filterInventorizations(data)),
    toggleFilters: () => dispatch(toggleFilters()),
    resetFilters: () => dispatch(resetFilters()),
  })
)(InventorizationsList);
