import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "INVENTORIZATIONS_TOGGLE_FILTERS" });
};

export const resetFilters = () => (dispatch) => {
  dispatch({ type: "INVENTORIZATIONS_RESET_FILTERS" });
};

export const filterInventorizations = (query) => (dispatch) => {
  dispatch({ type: "INVENTORIZATIONS_SET_FILTERS_QUERY", query });
};

export const getInventorizations =
  ({ page = 1, per_page = 20, ...rest }) =>
  async (dispatch, getState) => {
    try {
      const {
        status,
        filters: { query },
      } = getState().inventorizations;

      dispatch({
        type:
          status === "invalid"
            ? "INVENTORIZATIONS_LOADING"
            : "INVENTORIZATIONS_SEARCHING",
      });
      const { data, meta } = await client_v2.get(
        `/warehouse_inventorizations?${stringifyQuery({
          ...rest,
          page,
          per_page,
          context: "ui_index",
          ...query,
        })}`
      );
      dispatch({
        type: "INVENTORIZATIONS_SUCCESS",
        data,
        meta,
      });
    } catch (error) {
      dispatch({ type: "INVENTORIZATIONS_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania danych o inwentaryzacjach"
      );
      throw error;
    }
  };

export const createInventorization = async (values) => {
  try {
    const {
      data: { id },
    } = await client_v2.post("/warehouse_inventorizations", values);
    return id;
  } catch (error) {
    toastr.error("Błąd", getErrorMessage(error));

    throw error;
  }
};

export const getInventorization = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "INVENTORIZATION_LOADING",
    });
    const { data } = await client_v2.get(
      `/warehouse_inventorizations/${id}?context=ui_show`
    );
    const { data: tasks, meta } = await client_v2.get(
      `/warehouse_inventorizations/${id}/preview_tasks`
    );
    dispatch({
      type: "INVENTORIZATION_SUCCESS",
      data,
      tasks,
      meta,
    });
  } catch (error) {
    dispatch({ type: "INVENTORIZATION_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania danych o inwentaryzacji"
    );
    throw error;
  }
};

export const createInventorizationTasks = (id) => async (dispatch) => {
  try {
    await client_v2.post(`/warehouse_inventorizations/${id}/create_tasks`);
    dispatch(getInventorization(id));
  } catch (error) {
    throw error;
  }
};

export const filterTasks = (value) => (dispatch) =>
  dispatch({ type: "INVENTORIZATION_FILTER_TASKS", value });

export const paginateTasks = (page) => (dispatch) =>
  dispatch({ type: "INVENTORIZATION_PAGINATE_TASKS", page });

export const sortTasks = (sort) => (dispatch) => {
  dispatch({ type: "INVENTORIZATION_SORT_TASKS", sort });
  dispatch({ type: "INVENTORIZATION_PAGINATE_TASKS", page: 1 });
};
