const initialState = {
  status: "invalid",
  search_value: "",
  sort: "position_asc",
  all_tasks: [],
  meta: {
    page: 1,
    per_page: 100,
    total_pages: 1,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "INVENTORIZATION_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "INVENTORIZATION_SUCCESS":
      return {
        ...state,
        status: "success",
        data: { ...action.data, meta: action.meta },
        all_tasks: action.tasks,
        paginated_tasks: action.tasks.slice(0, state.meta.per_page),
        meta: {
          ...state.meta,
          total_pages: Math.ceil(action.tasks.length / state.meta.per_page),
        },
      };
    case "INVENTORIZATION_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "INVENTORIZATION_FILTER_TASKS": {
      const filtered_tasks =
        action.value.length > 0
          ? state.all_tasks.filter(
              ({
                product_main_location: {
                  warehouse_position: { identifier },
                },
                product: { name, sku },
              }) =>
                identifier.toLowerCase().includes(action.value.toLowerCase()) ||
                name.toLowerCase().includes(action.value.toLowerCase()) ||
                sku.toLowerCase().includes(action.value.toLowerCase())
            )
          : state.all_tasks;
      return {
        ...state,
        search_value: action.value,
        paginated_tasks: filtered_tasks.slice(0, state.meta.per_page),
        meta: {
          ...state.meta,
          page: 1,
          per_page: state.meta.per_page,
          total_pages: Math.ceil(filtered_tasks.length / state.meta.per_page),
        },
      };
    }
    case "INVENTORIZATION_PAGINATE_TASKS": {
      const filtered_tasks =
        state.search_value.length > 0
          ? state.all_tasks.filter(
              ({
                product_main_location: {
                  warehouse_position: { identifier },
                },
                product: { name, sku },
              }) =>
                identifier
                  .toLowerCase()
                  .includes(state.search_value.toLowerCase()) ||
                name.toLowerCase().includes(state.search_value.toLowerCase()) ||
                sku.toLowerCase().includes(state.search_value.toLowerCase())
            )
          : state.all_tasks;
      return {
        ...state,
        paginated_tasks: filtered_tasks.slice(
          (action.page - 1) * state.meta.per_page,
          action.page * state.meta.per_page
        ),
        meta: {
          ...state.meta,
          page: action.page,
        },
      };
    }
    case "INVENTORIZATION_SORT_TASKS": {
      if (action.sort === "result_asc") {
        const tasks = [...state.all_tasks];

        const task_without_prices = tasks.filter(
          ({ inventorization_prices_diff_result }) =>
            inventorization_prices_diff_result === null
        );
        const task_with_prices = tasks
          .filter(
            ({ inventorization_prices_diff_result }) =>
              inventorization_prices_diff_result !== null
          )
          .sort(
            (a, b) =>
              Number(a.inventorization_prices_diff_result) -
              Number(b.inventorization_prices_diff_result)
          );
        const all_tasks = [...task_with_prices, ...task_without_prices];
        return {
          ...state,
          sort: action.sort,
          all_tasks,
        };
      }
      if (action.sort === "result_desc") {
        const tasks = [...state.all_tasks];

        const task_without_prices = tasks.filter(
          ({ inventorization_prices_diff_result }) =>
            inventorization_prices_diff_result === null
        );
        const task_with_prices = tasks
          .filter(
            ({ inventorization_prices_diff_result }) =>
              inventorization_prices_diff_result !== null
          )
          .sort(
            (a, b) =>
              Number(b.inventorization_prices_diff_result) -
              Number(a.inventorization_prices_diff_result)
          );
        const all_tasks = [...task_with_prices, ...task_without_prices];
        return {
          ...state,
          sort: action.sort,
          all_tasks,
        };
      }
      if (action.sort === "position_asc") {
        const all_tasks = [...state.all_tasks].sort((a, b) =>
          a.product_main_location?.warehouse_position?.identifier?.localeCompare(
            b.product_main_location?.warehouse_position?.identifier
          )
        );
        return {
          ...state,
          sort: action.sort,
          all_tasks,
        };
      }
      if (action.sort === "position_desc") {
        const all_tasks = [...state.all_tasks].sort((a, b) =>
          b.product_main_location?.warehouse_position?.identifier?.localeCompare(
            a.product_main_location?.warehouse_position?.identifier
          )
        );
        return {
          ...state,
          sort: action.sort,
          all_tasks,
        };
      }
    }
    default:
      return state;
  }
};
